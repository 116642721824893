.dashboard-page {
  width: 100vw;
  height: 75vh;
  /*calc(100vh - 4rem); */
}
.dashboard-container {
  padding: 0px 27px;
}
.dashboard-header {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #01c0c8;
  /* #0057b7; */
}
.nav-tabs .nav-link {
  color: #6e7488;
  width: 100%;
  /* margin-left: 3rem; */
}
.nav-link:hover {
  background-color: #fff !important;
  color: #01c0c8 !important;
}
.nav-link.active {
  color: #01c0c8 !important;
}
.app-search {
  border-radius: 60px;
  border-color: transparent;
  font-weight: 400;
  color: currentColor;
  padding: 0.375rem 0.75rem;
  transition: 0.2s ease-in;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
  transition-delay: 0s;
  letter-spacing: inherit;
  font: inherit;
  width: 180px;
  height: 38px;
  margin-top: 16px;
}
.app-search:focus {
  width: 300px;
  height: 38px;
  font: inherit;
  letter-spacing: inherit;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  color: currentColor;
  background-color: #fff;
  appearance: none;
  border-radius: 60px;
  outline: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.dashboard-items {
  position: sticky;
  z-index: 1000;
  height: 80px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 3px 3px #b1b1b1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.add-bttn {
  text-transform: none !important;
  border-radius: 36px !important;
  background: #01c0c8 !important;
  color: #fff !important;
  border: none !important;
  margin-left: 32rem !important;
}
.add-bttn:hover {
  background: #01abb0 !important;
  color: #fff !important;
}
.save-bttn {
  font-weight: 600 !important;
  text-transform: none !important;
  border: 1px solid #fff !important;
  color: #fff !important;
  background-color: #1abc9c !important;
}
.save-bttn:hover {
  background-color: #15967d !important;
  color: #fff !important;
}
.clear-bttn {
  text-transform: none !important;
  border: 1px solid #fff !important;
  color: #fff !important;
  background-color: #6c757d !important;
}
.clear-bttn:hover {
  background-color: #5c636a !important;
  color: #fff !important;
}
.message {
  max-width: 15rem;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}
.success {
  color: green;
}
.error {
  color: #d32f2f;
}
.add_params {
  width: 30rem;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.call-bttn {
  font-weight: 600 !important;
  text-transform: none !important;
  border: 1px solid #4caf50 !important;
  color: #4caf50 !important;
  /* background-color: #1abc9c !important; */
}
.call-bttn:hover {
  background-color: #4caf50 !important;
  color: #fff !important;
}
.-bttn {
  text-transform: none !important;
  border: 1px solid #f44336 !important;
  color: #f44336 !important;
  /* background-color: #6c757d !important; */
}
.endcall-bttn:hover {
  background-color: #f44336 !important;
  color: #fff !important;
}
.temp2 {
  position: sticky;
  top: 3.5rem;
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: whitesmoke;
  padding: 0.5rem;
  /* color: white; */
  /* text-align: center; */
}

.btn1 {
  background-color: white;
  color: #01c0c8;
  padding: 5px 15px;
}
.currentbtn {
  background-color: #01abb0 !important;
  color: white !important;
  font-size: 12px !important;
}
.currentbtnfont {
  font-size: 12px !important;
  background-color: #fff !important;
}
/* 
*::-webkit-scrollbar {
  width: 1px;
}

*::-webkit-scrollbar-track {
  background: #01c0c8;
}

*::-webkit-scrollbar-thumb {
  background-color: #01c0c8;
  border-radius: 5px;
  border: 4px solid #fff;
} */
.scrollbar {
  /* margin-left: 30px; */
  /* float: left; */
  height: auto;
  /* width: 65px; */
  /* background: #f5f5f5; */
  overflow: scroll;
  overflow-x: hidden;
  /* margin-bottom: 25px; */
}
#style-5::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#style-5::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

#style-5::-webkit-scrollbar-thumb {
  background-color: #ff8404;
  /* orange; */
  /* #01abb0; */

  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
}

::-webkit-scrollbar {
  height: 7px; /* height of horizontal scrollbar ← You're missing this */
}
.itembottom {
  background-color: #01abb0;

  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
}
.outofstock {
  background-color: #ffa5004d;
  box-shadow: 0 0 5px 0 rgb(90 90 90 / 15%);
}
.m-fadeOut {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;
}
.m-fadeIn {
  animation: fadeIn 1s linear;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
textarea {
  resize: none;
}
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  cursor: pointer;
  /* font-size: 25px; */
}

/* Hide the default checkbox */
.container input {
  visibility: hidden;
  cursor: pointer;
}

/* Create a custom checkbox */
.mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 23px;
  width: 23px;
  background-color: lightgray;
}

.container:hover input ~ .mark {
  background-color: gray;
}

.container input:checked ~ .mark {
  background-color: #01bec6;
}

/* Create the mark/indicator (hidden when not checked) */
.mark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the mark when checked */
.container input:checked ~ .mark:after {
  display: block;
}

/* Style the mark/indicator */
.container .mark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.showdiv {
  display: block;
}
.hidediv {
  display: none;
}

/* Glowing button */
.glowing-btn {
  position: relative;
  color: var(--glow-color);
  cursor: pointer;
  padding: 0.35em 1em;
  border: 0.15em solid var(--glow-color);
  border-radius: 0.45em;
  background: none;
  perspective: 2em;
  font-family: "Raleway", sans-serif;
  font-size: 2em;
  font-weight: 900;
  letter-spacing: 1em;

  -webkit-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  -moz-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  animation: border-flicker 2s linear infinite;
}

.glowing-txt {
  float: left;
  margin-right: -0.8em;
  -webkit-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3),
    0 0 0.45em var(--glow-color);
  -moz-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3),
    0 0 0.45em var(--glow-color);
  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em var(--glow-color);
  animation: text-flicker 3s linear infinite;
}

.faulty-letter {
  opacity: 0.5;
  animation: faulty-flicker 2s linear infinite;
}

.glowing-btn::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.7;
  filter: blur(1em);
  transform: translateY(120%) rotateX(95deg) scale(1, 0.35);
  background: var(--glow-color);
  pointer-events: none;
}

.glowing-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: -1;
  background-color: var(--glow-color);
  box-shadow: 0 0 2em 0.2em var(--glow-color);
  transition: opacity 100ms linear;
}

.glowing-btn:hover {
  color: rgba(0, 0, 0, 0.8);
  text-shadow: none;
  animation: none;
}

.glowing-btn:hover .glowing-txt {
  animation: none;
}

.glowing-btn:hover .faulty-letter {
  animation: none;
  text-shadow: none;
  opacity: 1;
}

.glowing-btn:hover:before {
  filter: blur(1.5em);
  opacity: 1;
}

.glowing-btn:hover:after {
  opacity: 1;
}

@keyframes faulty-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 0.1;
  }
  4% {
    opacity: 0.5;
  }
  19% {
    opacity: 0.5;
  }
  21% {
    opacity: 0.1;
  }
  23% {
    opacity: 1;
  }
  80% {
    opacity: 0.5;
  }
  83% {
    opacity: 0.4;
  }

  87% {
    opacity: 1;
  }
}

@keyframes text-flicker {
  0% {
    opacity: 0.1;
  }

  2% {
    opacity: 1;
  }

  8% {
    opacity: 0.1;
  }

  9% {
    opacity: 1;
  }

  12% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0.3;
  }
  30% {
    opacity: 1;
  }

  70% {
    opacity: 0.7;
  }
  72% {
    opacity: 0.2;
  }

  77% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.9;
  }
}

@keyframes border-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 1;
  }
  4% {
    opacity: 0.1;
  }

  8% {
    opacity: 1;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .glowing-btn {
    font-size: 1em;
  }
}
.overflowspan {
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.itemofthedayhead {
  color: #1976d2;
  font-weight: bolder;
}
.itemofthedaybody {
  font-style: oblique;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
}
.itemofthedaybody1 {
  font-style: oblique;
  font-weight: 500;
  color: #fff;
}
.itemdelay {
  background-color: #1234;
  box-shadow: 0 0 5px 0 rgb(90 90 90 / 15%);
}
.cendiv {
  position: absolute;
  /* left: 50%; */
  width: 100vw;
  top: 50%;
  /* -webkit-transform: translate(-50%, -50%); */
  /* transform: translate(-50%, -50%); */
  /* animation: mymove 3s infinite; */
}

.rot {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
