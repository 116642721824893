.login-page {
  top: 0;
  bottom: 0;
  min-height: 100vh;
  z-index: 999;
  opacity: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  overflow: hidden;
  background: #fff;
  border-radius: 0 0 350px 0;
}
.container-fluid {
  width: 100%;
}
.login-form {
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  background: linear-gradient(132deg, #01c0c8, #591bc5);
  background-size: 400% 400%;
}
.form-info {
  min-height: 100vh;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 170px 100px;
}
.form-title > h1 {
  color: #ff8e00;
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 600;
  color: linear-gradient(to bottom, #ffb400, #ff8a00);
  background-size: cover;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 150px;
}
.form-container {
  max-width: 450px;
  width: 100%;
  margin-left: 6rem;
  margin-right: auto;
  text-align: center;
}
.form-heading {
  margin: 0 0 30px;
  font-size: 25px;
  font-weight: 400;
  color: #fff;
}
.form-input > input {
  width: 100%;
  padding: 10px 20px;
  font-size: 17px;
  outline: none;
  height: 55px;
  background: rgba(23, 23, 23, 0.72);
  color: #616161;
  border-radius: 50px;
  font-weight: 500;
  border: 1px solid transparent;
  background: #fff;
  box-shadow: 0 0 5px rgb(0 0 0 / 20%);
  line-height: 1.5;
  display: block;
}
.employee {
  margin-bottom: 1.5rem;
}
#emp-icon {
  background-image: url(../../images/user.png);
  background-repeat: no-repeat;
  text-indent: 1.2rem;
  background-position: 430px 15px;
  background-size: 1.4rem;
}
#pass-icon {
  background-image: url(../../images/lock.png);
  background-repeat: no-repeat;
  text-indent: 1.2rem;
  background-position: 432px 15px;
  background-size: 1.2rem;
}
.signin-btn {
  background-image: linear-gradient(to bottom, #ffb400, #ff8a00);
  padding: 12px 50px 12px 50px;
  font-size: 17px;
  font-family: "Jost", sans-serif;
  cursor: pointer;
  float: right;
  line-height: inherit;
  outline: none;
  box-shadow: 0 0 5px rgb(0 0 0 / 20%);
  border: none;
  color: #fff;
  border-radius: 50px;
  font-weight: 400;
}
.signin-btn:hover {
  background: #ffb100;
}
@media only screen and (max-width: 600px) {
  .form-container {
    width: 250px;
    height: 80%;
  }
  .form-heading {
    margin-right: 4rem;
  }
  .form-input > input {
    width: 80%;
    height: 40px;
  }
  .signin-btn {
    padding: 8.5px 20px 7.5px 20px;
  }
  .forget-password {
    margin-right: 8rem;
  }
}
@media only screen and (max-width: 711px) {
  .signin-btn {
    padding: 8.5px 20px 7.5px 20px;
  }
}
